<template>
  <div id="footer">
    <div class="top">
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item title="面向商户" name="1">
          <div></div>
          <div></div>
        </el-collapse-item>
        <el-collapse-item title="面向用户" name="2">
          <div>线上商城</div>
          <div>云店</div>
        </el-collapse-item>
        <el-collapse-item title="仟家惠" name="3">
          <div></div>
          <div></div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="footerImg">
      <span class="span-box" @click="show = true">
        <img src="../assets/imgsM/wb.png" alt=""
      /></span>
      <span @click="showWx=true"> <img src="../assets/imgsM/wx.png" alt="" /></span>
    </div>
    <div class="footerBottom">
      <p>版权所有©️2021 易雪儿科技(武汉)有限公司</p>
      <p>(武汉)有限公司 合作条款 / 鄂ICP备17000861号-1 </p>
    </div>
    <!-- 遮罩层 -->
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper">
        <img class="block" src="../assets/imgsM/sinatv-qrcode.png">
      </div>
    </van-overlay>
    <van-overlay :show="showWx" @click="showWx = false">
      <div class="wrapper">
        <img class="block" src="../assets/imgsM/qrcode.jpg">
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      showWx:false,
      activeName: 1,
    };
  },
};
</script>

<style lang="less" scoped>
#footer {
  width: 100vw;
  background-color: #f1f1f1;
  box-sizing: border-box;
  padding: 1.5rem 1.5rem 7rem 1.5rem;
  /deep/.el-collapse-item__header {
    background-color: #f1f1f1;
    font-size: 2rem;
    font-weight: bold;
    padding-left: 1.5rem;
  }
  /deep/.el-collapse-item__content {
    background-color: #f1f1f1;
    color: #999999;
  }

  .el-collapse-item {
    border-bottom: 1px solid #c7c7c7;
  }
  .footerImg {
    display: flex;
    align-items: centers;
    margin-top: 3rem;
    box-sizing: border-box;
    padding-left: 1.5rem;
    span {
      display: inline-block;
      width: 25px;
      // height: 18px;
      margin-right: 2rem;
      img {
        width: 100%;
        // height: 100%;
      }
    }
    .span-box {
      width: 22px !important;
    }
  }
  .footerBottom {
    padding-left: 1.5rem;
    margin-top: 3rem;
    font-size: 1.5rem;
    color: #333333;
    p {
      margin-bottom: 1rem;
    }
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  display: block;
  width: 120px;
  height: 120px;
  background-color: #fff;
}
</style>
