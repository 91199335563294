<template>
  <div id="app">
    <header-vue v-if="isShowPc"></header-vue>
    <header-wap v-if="isShowWap"></header-wap>
    <content-vue></content-vue>
    <footer-vue v-if="isShowPc"></footer-vue>
    <footer-wap v-if="isShowWap"></footer-wap>
  </div>
</template>

<script>
import headerVue from "./components/header.vue";
import headerWap from "./components/headerWap.vue";
import contentVue from "./components/content.vue";
import footerVue from "./components/footer.vue";
import footerWap from "./components/footerWap.vue";
export default {
  name: "app",
  components: {
    headerVue,
    contentVue,
    footerVue,
    headerWap,
    footerWap,
  },
  data() {
    return {
      isShowPc: false,
      isShowWap: false,
    };
  },
  mounted() {
    if (
      !navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      this.$router.replace("/home");
      this.isShowPc = true;
      window.addEventListener('resize', function(){
        document.body.style.zoom = 1;
        document.body.style.zoom = document.body.clientWidth / 2000;
      });
    
    } else {
      this.$router.replace("/homeWap");
      this.isShowWap = true;
    }
  },
  created() {
    // 判断是微信打开还是浏览器打开
    let ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      this.isWeixin = true;
    } else {
      this.isWeixin = false;
    }
  },
};
</script>


<style lang="less">
#app {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
</style>
