<template>
  <div id="footer">
    <div class="footerTop">
      <div class="select">
        <ul class="shop selectItem">
          <li>面向商户</li>
          <li><router-link to="/project/business">联盟商户</router-link></li>
          <li><router-link to="/project/business"> 数据中心</router-link></li>
          <li><router-link to="/project/business"> 服务商</router-link></li>
        </ul>
        <ul class="user selectItem">
          <li>面向用户</li>
          <li><a href="#"> 线上商城</a></li>
          <li><a> 云店</a></li>
        </ul>
        <ul class="xer selectItem">
          <li>仟家惠</li>
          <li @click="goLink('about')"><span> 关于</span></li>
          <li @click="goLink()"><span>企业责任</span></li>
          <li @click="goLink('supply')"><span>供应链</span></li>
          <li @click="goLink('backstage')"><span>后台入口</span></li>
        </ul>
      </div>
      <div class="downLoad">
        <div class="downLoadLeft">
          <a
            href=""
            @mouseover="sinatvQrFlag = true"
            @mouseleave="sinatvQrFlag = false"
            ><img class="img" src="../assets/wb.png" alt=""
          /></a>
          <a
            href=""
            @mouseover="qrcodeFlag = true"
            @mouseleave="qrcodeFlag = false"
            ><img class="img" src="../assets/wx.png" alt=""
          /></a>
          <img v-if="qrcodeFlag" class="qrcode" src="../assets/qrcode.jpg" />
          <img
            v-if="sinatvQrFlag"
            class="sinatv-qr"
            src="../assets/sinatv-qrcode.png"
          />
        </div>
        <div class="downLoadRight">
          <a
            href="http://static.whzd168.com/app_apk/download_html/downloadshop.html"
          >
            <img src="../assets/shopApp1.png" alt="" />
            <span>下载商户版APP</span>
          </a>
          <!-- <a
            href="http://static.whzd168.com/app_apk/download_html/download.html"
          >
            <img src="../assets/userApp1.png" alt="" />
            <span>下载用户版APP</span>
          </a> -->
        </div>
      </div>
    </div>
    <div class="footerBottom">
      版权所有©️2021 易雪儿科技(武汉)有限公司 / 合作条款 / 鄂ICP备17000861号-1 
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      qrcodeFlag: false,
      sinatvQrFlag: false,
    };
  },
  methods: {
    //去页面最顶部的方法
    goTop() {
      let t = document.documentElement.scrollTop || document.body.scrollTop;
      let timer = setInterval(function () {
        // 高度自减回收过程
        // t -= 180;
        t = 0;
        console.log(1);
        // 当高度为0清除定时器 - 停止动画
        if (t <= 0) {
          clearInterval(timer);
        }
        // 重新将高度t赋值给滚动的高度
        document.documentElement.scrollTop = document.body.scrollTop = t;
      }, 20);
    },
    goLink(type) {
      //关于
      if (type === "about") {
        if (this.$route.path === "/home") {
          setTimeout(() => {
            document
              .querySelector(".navCard")
              .scrollIntoView({ behavior: "smooth" });
          }, 300);
        } else {
          this.$router.push("/home");
          setTimeout(() => {
            document
              .querySelector(".navCard")
              .scrollIntoView({ behavior: "smooth" });
          }, 30);
        }
      }
      //供应链
      if (type === "supply") {
        if (this.$router.path === "/supplychain/supplier") {
          this.goTop();
        } else {
          this.$router.push("/supplychain/supplier");
          this.goTop();
        }
      }
      //后台入口
      if (type === "backstage") {
        if (this.$router.path === "/serve/stage") {
          this.goTop();
        } else {
          this.$router.push("/serve/stage");
          this.goTop();
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
#footer {
  width: 1920px;
  height: 604px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .footerTop {
    background-color: #333333;
    width: 100%;
    height: 516px;
    box-sizing: border-box;
    padding: 70px 58px 0 58px;
    .select {
      height: 336px;
      display: flex;
      border-bottom: 1px solid #f7f7f7;
      a {
        color: #fff;
      }
      .selectItem {
        width: 166px;
        margin-right: 100px;
        li {
          line-height: 60px;
          text-indent: 40px;
          cursor: pointer;
          font-size: 14px;
          &:nth-child(1) {
            border-bottom: 1px solid #f7f7f7;
            height: 46px;
            line-height: 46px;
            font-size: 18px;
            font-weight: 500;
            color: #fefeff;
            text-align: center;
            text-indent: 0;
          }
        }
      }
    }
    .downLoad {
      height: 96px;
      padding-right: 75px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      .downLoadLeft {
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        .img {
          width: 33px;
          // height: 25px;
          margin-left: 35px;
        }
        .qrcode {
          position: absolute;
          width: 96px;
          height: 96px;
          top: -72px;
          left: 74px;
        }
        .sinatv-qr {
          position: absolute;
          width: 96px;
          height: 96px;
          top: -72px;
          left: 6px;
        }
      }
      .downLoadRight {
        display: flex;
        align-items: center;
        a {
          margin-left: 40px;
          display: flex;
          align-items: center;
          span {
            color: #fefeff;
            margin-left: 17px;
          }
        }
      }
    }
  }
  .footerBottom {
    width: 100%;
    height: 88px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
