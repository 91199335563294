<template>
  <div class="container">
    <div id="header">
      <img
        class="openLogo"
        :src="
          flag == true
            ? require('../assets/imgsM/class1.png')
            : require('../assets/imgsM/no.png')
        "
        alt=""
        @click="openMenu"
      />
      <router-link to="/homeWap"
        ><img class="logoimg" src="../assets/imgsM/logo1.png" alt=""
      /></router-link>
      <div class="nav" v-if="menuShow">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="项目运营" name="1">
            <div @click="closeMenu">
              <router-link to="/project/marketingWap">营销系统</router-link>
            </div>
            <div @click="closeMenu">
              <router-link to="/project/analysisWap">项目解析</router-link>
            </div>
            <div @click="closeMenu">
              <router-link to="/project/businessWap">商务中心</router-link>
            </div>
          </el-collapse-item>
          <el-collapse-item title="数字经济" name="2">
            <div @click="closeMenu">
              <router-link to="/number/intergralWap">积分系统</router-link>
            </div>
            <div @click="closeMenu">
              <router-link to="/number/consumptionWap">消费增值</router-link>
            </div>
          </el-collapse-item>
          <el-collapse-item title="供应链" name="3">
            <div @click="closeMenu">
              <router-link to="/supplychain/cloudWap">云仓</router-link>
            </div>
            <div @click="closeMenu">
              <router-link to="/supplychain/operationWap">运作机制</router-link>
            </div>
            <div @click="closeMenu">
              <router-link to="/supplychain/supplierWap"
                >成为供应商</router-link
              >
            </div>
          </el-collapse-item>
          <el-collapse-item title="服务" name="5">
            <div @click="closeMenu">
              <router-link to="/serve/downloadWap">下载中心</router-link>
            </div>
            <div @click="closeMenu">
              <router-link to="/serve/contactWap">联系我们</router-link>
            </div>
          </el-collapse-item>
          <el-collapse-item title="最新动态" name="4">
            <div @click="closeMenu">
              <router-link to="/news/messageWap">最新动态</router-link>
            </div>
          </el-collapse-item>
          <!-- <el-collapse-item title="最新动态" name="4"> </el-collapse-item> -->

        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //配置移动端页面meta
  metaInfo() {
    return {
      meta: [
        {
          name: "viewport",
          content:
            "width=device-width,user-scalable=0,initial-acale=1.0,maximum-scale=1.0,",
        },
      ],
    };
  },
  data() {
    return {
      activeName: "1",
      menuShow: false,
      flag: true,
      navList: [
        {
          title: "项目运营",
          children: [
            { title: "营销系统", url: "/project/marketingWap" },
            { title: "项目解析", url: "/project/analysisWap" },
            { title: "商务中心", url: "/project/businessWap" },
          ],
        },
        {
          title: "数字经济",
          children: [
            { title: "积分系统", url: "/number/intergralWap" },
            { title: "消费增值", url: "/number/consumptionWap" },
          ],
        },
        {
          title: "供应链",
          children: [
            { title: "云仓", url: "/supplychain/cloudWap" },
            { title: "运作机制", url: "/supplychain/operationWap" },
            { title: "成为供应商", url: "/supplychain/supplierWap" },
          ],
        },
        {
          title: "服务",
          children: [
            { title: "下载中心", url: "/serve/downloadWap" },
            { title: "联系我们", url: "/serve/contactWap" },
          ],
        },
        {
          title: "最新动态",
          children: [
             { title: "最新动态", url: "/news/messageWap" },
          ],
        },
      ],
    };
  },
  methods: {
    openMenu() {
      this.menuShow = !this.menuShow;
      this.flag = !this.flag;
    },
    closeMenu() {
      this.menuShow = !this.menuShow;
      this.flag = !this.flag;
    },
  },
};
</script>

<style lang="less" scoped>
html,
body {
  margin: 0;
  padding: 0;
  border: none;
  // font-size: 2.67vw;
}

#header {
  width: 100vw;
  z-index: 100;
  // font-size: 2.4rem;
  height: 60pt;
  display: flex;
  align-items: center;
  position: relative;
  img {
    margin-left: 20pt;
  }
  .logoimg {
    width: 11rem;
    margin-left: 14pt !important;
  }
  .openLogo {
    width: 2.5rem;
  }
  .nav {
    position: absolute;
    top: 60pt;
    width: 100vw;
    // height: 0;
    overflow: hidden;
    box-sizing: border-box;
    // padding: 1.5rem;

    /deep/.el-collapse-item__header {
      padding-left: 1.5rem;
      font-size: 2rem;
      font-weight: bold;
    }
    a {
      padding-left: 1.5rem;
    }
  }
}
</style>
