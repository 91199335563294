<template>
    <div id="content">
        <router-view></router-view>
       
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less" scoped>

</style>