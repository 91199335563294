<template>
  <div
    id="header"
    :class="[
      $route.path === '/project/analysis' ? 'active' : '',
      $route.path === '/project/marketing' ? 'active' : '',
      $route.path === '/project/business' ? 'active' : '',
      $route.path === '/number/consumption' ? 'active' : '',
    ]"
  >
    <router-link to="/"
      ><img src="../assets/logo.png" alt="" class="logo"
    /></router-link>
    <div
      class="nav"
      v-for="(item, index) in list"
      :key="index"
      @click="openChildren(index)"
      @mousemove="openModel(index)"
    >
      {{ item.title }}
      <span class="iconfont" :class="item.icon"></span>
      <div
        class="navSon"
        v-for="(v, i) in item.children"
        :class="['navSon' + index, 'navchildren' + i]"
        :key="i"
        @click="toChildren(index, i)"
      >
        <router-link :to="v.url">{{ v.title }}</router-link>
      </div>
    </div>

    <div class="headerModel" ref="headerModel" @mouseleave="close">
      <ul
        class="headerNav"
        v-for="(item, index) in list"
        :key="item.id"
        :class="'headerNav' + index"
      >
        <li v-for="v in item.children" :key="v.id">
          <div class="headerNavTitle" @click="toNext(v.url)">{{ v.title }}</div>
          <ul class="headerNavContent">
            <li
              v-for="j in v.children"
              :key="j.id"
              @click="closeModel(v.url, j.class)"
            >
              {{ j.title }}
              <span class="tel" v-if="j.class == 'tel'">027-59304338</span>
              <span class="tel" v-if="j.class == 'email'"
                >service@whzd168.com</span
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "1",
      flag: false, //判断子级nav是否展开，false为未展开
      clickFlag: false,
      list: [
        {
          title: "项目运营",
          icon: "icon-jiantou_liebiaozhankai_o",
          children: [
            {
              title: "营销系统",
              url: "/project/marketing",
              children: [
                { title: "系统架构", class: "marketingTop" },
                { title: "软著产权", class: "honor" },
              ],
            },
            {
              title: "项目解析",
              url: "/project/analysis",
              children: [
                { title: "运营架构", class: "platform" },
                { title: "生态样板", class: "zoology" },
                { title: "商户数字化", class: "digitization" },
                { title: "积分店", class: "shop" },
                { title: "云店", class: "cloud" },
              ],
            },
            {
              title: "商务中心",
              url: "/project/business",
              children: [
                { title: "平台盈利点", class: "platform" },
                { title: "优势分析", class: "runTop" },
                { title: "数据中心盈利分析", class: "runBottom" },
                { title: "运营收益", class: "job" },
                { title: "商务合作", class: "partner" },
              ],
            },
          ],
        },
        {
          title: "数字经济",
          icon: "icon-jiantou_liebiaozhankai_o",
          children: [
            {
              title: "积分系统",
              url: "/number/integral",
              children: [
                { title: "积分模式", class: "pattern" },
                { title: "积分解析", class: "analysis" },
                { title: "积分管理", class: "sketch" },
              ],
            },
            {
              title: "消费增值",
              url: "/number/consumption",
              children: [
                { title: "增值模型", class: "model" },
                { title: "金融通道", class: "banking" },
                { title: "基金管理", class: "capital" },
              ],
            },
          ],
        },
        {
          title: " 供应链",
          icon: "icon-jiantou_liebiaozhankai_o",
          children: [
            {
              title: "云仓",
              url: "/supplychain/cloud",
              children: [{ title: "云仓介绍", class: "cloud" }],
            },
            {
              title: "运作机制",
              url: "/supplychain/operation",
              children: [{ title: "运作机制介绍", class: "operationImg" }],
            },
            {
              title: "供应链合作",
              url: "/supplychain/supplier",
              children: [{ title: "合作入口", class: "top" }],
            },
          ],
        },
        {
          title: "服务",
          icon: "icon-jiantou_liebiaozhankai_o",
          children: [
            {
              title: "下载中心",
              url: "/serve/download",
              children: [
                { title: "下载商户端", class: "topT" },
                // { title: "下载用户端", class: "user" },
                { title: "下载用户端", class: "topT" },
              ],
            },
            {
              title: "仟家惠后台入口",
              url: "/serve/stage",
              children: [{ title: "后台入口", class: "scrollTop" }],
            },
            {
              title: "联系我们",
              url: "/serve/contact",
              children: [
                { title: "联系电话", class: "tel" },
                { title: "联系邮箱", class: "email" },
                { title: "联系客服", class: "service" },
              ],
            },
          ],
        },
        {
          title: "最新动态",
          icon: "",
          url: "/news/message",
          children: [
            {
              title: "",
              url: "",
              children: [{ title: "", class: "" }],
            },
          ],
        },
      ],
    };
  },

  methods: {
    // 点击nav显示子级nav的事件
    openChildren(index) {
      if (!this.clickFlag) {
        document.querySelectorAll(".nav")[index].classList.remove("bg");
      }
      this.$refs["headerModel"].style.height = "0";
      let brotherNodes = document.querySelectorAll(".nav");
      let childrenNodes = document.querySelectorAll(`.navSon${index}`);
      if (!this.flag) {
        console.log("点击进入后走if");
        if (index === 4) {
          // childrenNodes.forEach((childrenNode) => {
          //   childrenNode.style.display = "none";
          // });
          this.$router.push("/news/message");
          // return;
        }
        brotherNodes.forEach((brotherNode) => {
          brotherNode.style.display = "none";
        });
        childrenNodes.forEach((childrenNode) => {
          childrenNode.style.display = "flex";
        });
        brotherNodes[index].firstElementChild.classList.remove(
          "icon-jiantou_liebiaozhankai_o"
        );
        brotherNodes[index].firstElementChild.classList.add(
          "icon-jiantou_liebiaoxiangzuo_o"
        );
        brotherNodes[index].style.display = "flex";
        brotherNodes[index].style.color = "#EC414D";
      } else {
        console.log("点击后返回走else");
        brotherNodes.forEach((brotherNode) => {
          brotherNode.style.display = "flex";
          if (index !== 4) {
            brotherNodes[index].firstElementChild.classList.add(
              "icon-jiantou_liebiaozhankai_o"
            );
          }

          brotherNodes[index].firstElementChild.classList.remove(
            "icon-jiantou_liebiaoxiangzuo_o"
          );
        });
        childrenNodes.forEach((childrenNode) => {
          childrenNode.style.display = "none";
          brotherNodes[index].style.color = "#000";
        });
      }
      this.flag = !this.flag;
    },
    // 点击下拉菜单里大标题的事件
    toNext(path) {
      this.$router.push({ path });
      this.$refs["headerModel"].style.height = "0";
    },
    // 点击子级nav的事件
    toChildren(idx1, idx2) {
      this.$refs["headerModel"].style.height = "0";
      this.flag = !this.flag;
      let childrenNodes = document.querySelectorAll(`.navSon${idx1}`);
      childrenNodes[idx2].style.display = "none";
    },
    // 鼠标移入nav显示下拉菜单事件
    openModel(index) {
      if (this.clickFlag) {
        document.querySelectorAll(".nav").forEach((item) => {
          item.classList.remove("bg");
        });
        document.querySelectorAll(".nav")[index].classList.add("bg");
      } else {
        this.$refs["headerModel"].style.height = "430px";
        document.querySelectorAll(".headerNav").forEach((item) => {
          item.style.display = "none";
        });
        document.querySelector(`.headerNav${index}`).style.display = "flex ";
        if (index === 4) {
          this.$refs["headerModel"].style.height = "0";
        }
      }
      this.list.forEach((item) => {
        item.icon = "icon-jiantou_liebiaozhankai_o";
        this.list[4].icon = "";
      });
      this.list[index].icon === "icon-jiantou_liebiaozhankai_o"
        ? (this.list[index].icon = "icon-xiangshangjiantou")
        : "icon-jiantou_liebiaozhankai_o";
      this.clickFlag = !this.clickFlag;
    },
    // 点击下拉菜单里面小标题的事件
    closeModel(path, name) {
      this.$refs["headerModel"].style.height = "0";
      if (this.$router.path !== path) {
        this.$router.push({ path });
        this.$nextTick(() => {
          document.querySelector(`.${name}`).scrollIntoView();
        });
      } else {
        this.$nextTick(() => {
          document.querySelector(`.${name}`).scrollIntoView();
        });
      }
      // console.log("之前" + Date.now());
      // setTimeout(() => {
      //   console.log("之后" + Date.now());
      //   // document.querySelector(`.${name}`).scrollIntoView();
      // }, 300);
    },
    // 隐藏下拉菜单事件
    close() {
      this.$refs["headerModel"].style.height = "0";
    },
  },
};
</script>

<style lang="less" scoped>
#header {
  height: 100px;
  width: 1920px;
  display: flex;
  align-items: center;
  position: relative;

  &.active {
    background-color: #ededed;
  }
  .logo {
    width: 9rem;
    margin-right: 60px;
    margin-left: 250px;
  }

  .nav {
    height: 100%;
    width: 14rem;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    // &.active {
    //   color: #ec414d;
    // }
    .iconfont {
      font-size: 25px;
      font-weight: 600;
      display: block;
    }
    .navchildren0 {
      left: 17.2rem;
    }
    .navchildren1 {
      left: 31.5rem;
    }
    .navchildren2 {
      left: 46rem;
    }
    .navchildren3 {
      left: 56rem;
    }
    .navchildren4 {
      left: 70rem;
    }
    .navSon {
      display: none;
      position: absolute;
      top: 0;

      a {
        display: inline-block;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        margin-right: 45px;
        font-weight: bold;
      }
    }
  }
  .headerModel {
    width: 1920px;
    height: 0;
    overflow: hidden;
    position: absolute;
    top: 100px;
    left: 0;
    background-color: rgb(250, 250, 250);
    z-index: 100;
    transition: height 0.5s;

    .headerNav {
      width: 100%;
      height: 100%;
      color: rgb(70, 70, 70);
      display: none;
      // justify-content: space-between;
      box-sizing: border-box;
      padding: 60px 580px;
      li {
        min-width: 120px;
        margin-right: 100px;
        .headerNavTitle {
          font-size: 18px;
          font-weight: 500;
          line-height: 45px;
          border-bottom: 1px solid rgb(70, 70, 70);
          margin-bottom: 32px;
          text-align: center;
          cursor: pointer;
        }
        .headerNavContent {
          box-sizing: border-box;
          padding-left: 28px;
          li {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 36px;
            cursor: pointer;
            .tel {
              display: block;
              margin-top: 10px;
              margin-bottom: -22px;
            }
          }
        }
      }
    }
  }
}
.bg {
  background-color: rgb(250, 250, 250);
}
</style>
