import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
const routes = [
  // {
  //   path: '/introduce_pc',
  //   name: 'introduce_pc',
  //   component: introduce_pc
  // },
  // {
  //   path: '/introduce_wap',
  //   name: 'introduce_wap',
  //   component: introduce_wap
  // },
  {
    path: '/',
    name: 'home',
    redirect: '/home',
    component: () => import('@/views/introduce_pc/home/home.vue'),
  },
  {
    path: '/home',
    component: () => import('@/views/introduce_pc/home/home.vue')
  },
  {
    path: '/number/consumption',
    component: () => import('@/views/introduce_pc/number/consumption.vue')
  },
  {
    path: '/number/integral',
    component: () => import('@/views/introduce_pc/number/integral.vue')
  },
  {
    path: '/project/analysis',
    component: () => import('@/views/introduce_pc/project/analysis.vue')
  },
  {
    path: '/project/marketing',
    component: () => import('@/views/introduce_pc/project/marketing.vue')
  },
  {
    path: '/project/business',
    component: () => import('@/views/introduce_pc/project/business.vue')
  },
  {
    path: '/serve/contact',
    component: () => import('@/views/introduce_pc/serve/contact.vue')
  },
  {
    path: '/serve/download',
    component: () => import('@/views/introduce_pc/serve/download.vue')
  },

  {
    path: '/serve/merchantPdf',
    component: () => import('@/views/introduce_pc/serve/merchantPdf.vue')
  },

  {
    path: '/serve/stage',
    component: () => import('@/views/introduce_pc/serve/stage.vue')
  },
  {
    path: '/supplychain/cloud',
    component: () => import('@/views/introduce_pc/supplychain/cloud.vue')
  },
  {
    path: '/supplychain/operation',
    component: () => import('@/views/introduce_pc/supplychain/operation.vue')
  },
  {
    path: '/supplychain/supplier',
    component: () => import('@/views/introduce_pc/supplychain/supplier.vue')
  },
  {
    path: '/news/message',
    component: () => import('@/views/introduce_pc/news/message.vue')
  },
  {
    path: '/news/detail',
    component: () => import('@/views/introduce_pc/news/detail.vue')
  },
  //移动端
  {
    path: '/homeWap',
    component: () => import('@/views/introduce_wap/home/home.vue')
  },
  {
    path: '/number/consumptionWap',
    component: () => import('@/views/introduce_wap/number/consumption.vue')
  },
  {
    path: '/number/intergralWap',
    component: () => import('@/views/introduce_wap/number/intergral.vue')
  },
  {
    path: '/project/analysisWap',
    component: () => import('@/views/introduce_wap/project/analysis.vue')
  },
  {
    path: '/project/marketingWap',
    component: () => import('@/views/introduce_wap/project/marketing.vue')
  },
  {
    path: '/project/businessWap',
    component: () => import('@/views/introduce_wap/project/business.vue')
  },
  {
    path: '/serve/contactWap',
    component: () => import('@/views/introduce_wap/serve/contact.vue')
  },
  {
    path: '/serve/downloadWap',
    component: () => import('@/views/introduce_wap/serve/download.vue')
  },
  {
    path: '/supplychain/cloudWap',
    component: () => import('@/views/introduce_wap/supplychain/cloud.vue')
  },
  {
    path: '/supplychain/operationWap',
    component: () => import('@/views/introduce_wap/supplychain/operation.vue')
  },
  {
    path: '/supplychain/supplierWap',
    component: () => import('@/views/introduce_wap/supplychain/supplier.vue')
  },
  {
    path: '/news/messageWap',
    component: () => import('@/views/introduce_wap/news/message.vue')
  },
  {
    path: '/news/detailWap',
    component: () => import('@/views/introduce_wap/news/detail.vue')
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router